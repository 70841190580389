<template>
  <v-sheet class="customer" id="adjustment">
    <v-row style="margin: 0% !important">
     <v-row> 
        <v-col md="6" class="my-auto py-0"> </v-col>
        <!-- {{ formValid }} -->
        <v-col md="6" class="text-right py-2">
          <v-btn  v-if="this.adjustment.stock != 'stock_transfer'"
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="updateOrCreate"
          >
            Save
          </v-btn>
          <v-btn v-if="this.adjustment.stock != 'stock_transfer'" class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Cancel
          </v-btn>
          <v-btn v-if="this.adjustment.stock == 'stock_transfer'"
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="updateOrCreateTransfer"
          >
            Save
          </v-btn>
          <v-btn v-if="this.adjustment.stock == 'stock_transfer'" class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Cancel
          </v-btn>
        </v-col>
      </v-row>

      <v-col md="12" class="mt-0 pa-0">
        <v-form
          ref="productAddForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <v-row style="margin: 0% !important; height: calc(100vh - 188px); overflow-y: auto">
            <v-col md="8" class="pl-0 pt-0">
              <v-row style="margin: 0% !important">
                <v-col md="12" class="border-light-grey">
                  <v-row style="margin: 0% !important">
                    <v-col md="12" class="my-0 py-0">
                      <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold">
                        Adjustment
                      </label>
                    </v-col>

                    <v-col md="12">
                      <v-row style="margin: 0% !important">
                        <v-col md="12" class="my-0 pa-0">
                          <v-radio-group row hide-details v-model="adjustment.stock">
                            <template v-for="(_radio, idx) in stock_radio">
                              <v-radio
                                :id="_radio.value"
                                :key="'$_' + _radio.title + '_' + _radio.value + '_' + idx"
                                color="#0d47a1"
                                
                                :style="`border: 1px solid ${
                                  adjustment.stock === _radio.value ? '#0d47a1' : '#dedcdc'
                                } !important;`"
                                :class="[
                                  'px-4',
                                  'py-2',
                                  'rounded-xl',
                                  { 'blue lighten-5': adjustment.stock === _radio.value },
                                ]"
                                :value="_radio.value"
                              >
                                <template v-slot:label>
                                  <label :for="_radio.value" class="field-label">
                                    {{ _radio.title }}
                                  </label>
                                </template>
                              </v-radio>
                            </template>
                          </v-radio-group>
                        </v-col>

                        <div
                          style="width: 100%"
                          class="mt-3 d-flex align-center justify-space-between"
                        >
                          <div style="width: 45%" class="mr-2">
                            <div class="">
                              <div>
                                <label for="adjustment_Date" class="field-label mt-0 mb-1 required">
                                  Date
                                </label>
                              </div>
                              <div>
                                <DatePicker
                                  hideTopMargin
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model="adjustment.date"
                                  id="adjustment_Date"
                                  placeholder="Date"
                                  :rules="[vrules.required(adjustment.date, 'supplier')]"
                                  :customClass="{ required: !adjustment.date }"
                                ></DatePicker>
                              </div>
                            </div>
                          </div>
                          <div style="width: 55%" class="ml-2">
                            <div style="margin: 0% !important">
                              <div class="pb-0 px-0">
                                <label for="reference_no" class="field-label mt-0 mb-1"
                                  >Reference No</label
                                >
                              </div>
                              <div class="my-0 pa-0">
                                <TextInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="reference_no"
                                  v-model="adjustment.reference_no"
                                  placeholder="Reference Number"
                                ></TextInput>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <v-col md="12" class="pb-0 px-0">
                          <label for="adjustmentFile" class="field-label mt-0 mb-1">Attach Document</label>
                        </v-col>
                        <v-col md="12" class="my-0 pa-0">
                          <FileUpload id="adjustmentFile" :directionColumn="false" :alignColumn="false" accept="image"
                            allowAddMore style="cursor: pointer" :customClassArr="['mt-0']" v-model="adjustment.file">
                          </FileUpload>
                        </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <!-- Warehouse -->
            <v-col md="4" class="pr-0 pt-0" v-if="this.adjustment.stock == 'stock_transfer'">
              <v-row
                style="margin: 0% !important; height: 100% !important"
                class="border-light-grey grey lighten-4"
              >
                <v-col md="12" class="my-0 pb-0 px-6">
                  <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold">
                    Warehouse
                  </label>
                </v-col>

                <v-col md="12" class="px-6 pb-6">
                  <v-row style="margin: 0% !important">
                    <v-col md="12" class="my-0 pa-0">
                     
                      <v-btn
                      class="white--text"
                            color="green"
                            height="34"
                            variant="flat"
                            style="border: 2px solid rgb(34, 167, 93)"
                          >
                          From Sutra
                          </v-btn>
                    </v-col>

                    <!-- <v-col md="12" class="my-0 pa-0 pb-3">
                      <SelectInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :items.sync="prod_lists.brand"
                        id="from_warehouse"
                        v-model="adjustment.from_warehouse"
                        placeholder="Brand"
                      ></SelectInput>
                    </v-col> -->

                    <v-col md="12" class="py-4 text-center">
                      <v-icon color="#0d47a1" style="font-size: 28px">mdi-arrow-down</v-icon>
                    </v-col>

                    <v-col md="12" class="pb-0 px-0">
                      <label for="target_warehouse" class="field-label mt-0 mb-1 required">
                        To Target Warehouse
                      </label>
                     
                    </v-col>
                    <v-col md="12" class="my-0 pa-0">
                      <AutoCompleteInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :items.sync="warehouses"
                        item-text="name"
                        :rules="[vrules.required(adjustment.to_warehouse, 'supplier')]"
                        :customClass="{ required: !adjustment.to_warehouse }"
                        id="target_warehouse"
                        v-model="adjustment.to_warehouse"
                        placeholder="warehouse"
                      ></AutoCompleteInput>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="12" class="px-0">
              <v-row style="margin: 0% !important">
                <v-col md="12" class="border-light-grey grey lighten-4">
                  <v-row style="margin: 0% !important">
                    <v-col md="12" class="my-0 py-0">
                      <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold">
                        Products 
                      </label>
                    </v-col>

                    <v-col md="12">
                      <v-row style="margin: 0% !important">
                        <v-col md="12" class="pb-0 px-0">
                          <label for="" class="field-label mt-0 mb-1 required"> Products </label>
                        </v-col>
                        <!-- td v-on:click="() => data.key !== '#' && routeToDetail(row.id)" -->
                        <v-col md="12" class="my-0 pa-0">
                          <v-simple-table   v-if="this.adjustment.stock != 'stock_transfer'" :class="['adjustment-table', 'grey lighten-4']">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    v-for="(product_h, index) in productTableHeading"
                                    :key="'$_' + product_h.title + '_' + index + 1"
                                    :style="`max-width: ${product_h.width} !important; width ${product_h.width} !important`"
                                    :width="product_h.width"
                                    :colspan="product_h.heading.includes('$') ? 2 : 1"
                                    :class="[
                                      { 'text-center px-2': product_h.title === '#' },
                                      { 'px-3': product_h.title === '' },
                                      'font-weight-medium',
                                      'black--text',
                                    ]"
                                  >
                                    {{ product_h.title }}
                                  </th>
                                </tr>
                              </thead>

                              <!-- table body -->
                              <tbody v-if="!pageLoading" v-click-outside="clickOutside">
                                <template v-if="adjustment_products.length">
                                  <tr
                                    v-for="(row, rowIndex) in adjustment_products"
                                    :class="rowIndex % 2 === 0 && 'blue lighten-5'"
                                    class="product-listing-tr"
                                    :key="'$adjustment_product_k_' + rowIndex"
                                    draggable
                                    @dragstart="dragStart(rowIndex)"
                                    @dragover="dragOver(rowIndex)"
                                    @dragend="dragEnd"
                                  >
                                    <td class="cursor-move px-0">
                                      <div class="d-flex align-center justify-center">
                                        <v-icon small color="#0d47a1">mdi-apps</v-icon>
                                      </div>
                                    </td>

                                    <td class="px-0">
                                      <div class="d-flex align-center justify-center">
                                        <span>{{ rowIndex + 1 }}</span>
                                      </div>
                                    </td>

                                    <td
                                      v-for="(r_data, r_key, dataIndex) in row"
                                      :key="
                                        r_key + '_' + dataIndex + '_' + r_key + dataIndex + '_' + 5
                                      "
                                      :class="[
                                        { 'cursor-move': r_key === 'drag' },
                                        {
                                          'px-5':
                                            rowIndex !== activeRowIndex &&
                                            (r_key.includes('type') || r_key.includes('serial_no')),
                                        },
                                        {
                                          'px-2':
                                            rowIndex === activeRowIndex &&
                                            (r_key.includes('type') || r_key.includes('serial_no')),
                                        },
                                      ]"
                                      :style="{
                                        maxWidth:
                                          productTableHeading.find((pt) => pt.heading === r_key)
                                            .width + 'px',
                                      }"
                                      :width="
                                        productTableHeading.find((pt) => pt.heading === r_key).width
                                      "
                                    >
                                      <div
                                        v-if="r_key.includes('current_stock')"
                                        style="height: 100%"
                                        class="d-flex flex-column justify-space-around"
                                      >
                                        <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.primary.value
                                          }}</span>
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.primary.uom
                                          }}</span>
                                        </div>
                                        <div class="d-flex align-center">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.secondary.value
                                          }}</span>
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.secondary.uom
                                          }}</span>
                                        </div>
                                      </div>

                                      <div v-else-if="r_key.includes('final_quantity')">
                                        <div>
                                          
                                        </div>
                                        <p class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span
                                            :class="[
                                              'ml-2',
                                              'font-weight-bold',
                                              {
                                                'red--text':
                                                  row['type'].value.name === 'subtraction',
                                              },
                                              {
                                                'green--text':
                                                  row['type'].value.name === 'addition',
                                              },
                                            ]"
                                            >{{ r_data.primary.value }}
                                          </span>
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.primary.uom
                                          }}</span>
                                        </p>
                                        <p class="d-flex align-center mb-0">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span
                                            :class="[
                                              'ml-2',
                                              'font-weight-bold',
                                              {
                                                'red--text':
                                                  row['type'].value.name === 'subtraction',
                                              },
                                              {
                                                'green--text':
                                                  row['type'].value.name === 'addition',
                                              },
                                            ]"
                                            >{{ r_data.secondary.value }}</span
                                          >

                                          <span class="font-weight-bold ml-2">{{
                                            r_data.secondary.uom
                                          }}</span>
                                        </p>
                                      </div>
                                      <div
                                        :class="[
                                          'd-flex align-center justify-start font-weight-medium',
                                        ]"
                                        v-else-if="r_key.includes('type')"
                                        @click="onFocus($event, rowIndex)"
                                        style="font-size: 1.2em; height: 100%"
                                      >
                                        <AutoCompleteInput
                                          v-if="rowIndex === activeRowIndex"
                                          style="width: 100px"
                                          hide-details
                                          :items.sync="adj_dds[r_key]"
                                          :id="`$_product-${row[r_key]}_${rowIndex + 1}`"
                                          :placeholder="r_key?.toUpperCase().replace('_', ' ')"
                                          itemText="title"
                                          itemValue="props"
                                          @change="calcType(r_data.value, rowIndex)"
                                          v-model="r_data.value"
                                        ></AutoCompleteInput>

                                        <template v-else>
                                          {{ r_data.value.name?.toUpperCase() }}</template
                                        >
                                      </div>

                                      <!-- @click="onFocus($event)" -->
                                      <!-- @click="
                                          (event) => {
                                            activeRowIndex = rowIndex;
                                            $nextTick(() => {
                                              onFocus(event, r_key + '_' + rowIndex);
                                            });
                                          }
                                        " -->
                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('serial_no')"
                                        :class="['font-weight-medium', 'd-flex align-center']"
                                        style="font-size: 1.2em; height: 100%"
                                      >
                                        <TextInput
                                          :id="r_key + '_' + rowIndex"
                                          :ref="r_key + '_' + rowIndex"
                                          v-if="rowIndex === activeRowIndex"
                                          hide-details
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          :fieldType="row[r_key].type"
                                          :placeholder="r_key?.toUpperCase().replace('_', ' ')"
                                          :minMax="{ min: 0, max: 1000 }"
                                          v-model="row[r_key].value"
                                          @change="changeInput(r_key)"
                                          
                                          customClass="px-0"
                                        ></TextInput>
                                        <template v-else>{{ r_data.value }}</template>
                                      </div>

                                      <!-- (event) => {
                                        activeRowIndex = rowIndex;
                                        $nextTick(() => {
                                          onFocus(event, r_key + '_' + rowIndex);
                                        });
                                      }
                                    " -->
                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('quantity')"
                                        style="height: 100%"
                                        class="d-flex flex-column justify-space-around py-1"
                                      >
                                        <!-- @click="onFocus(r_key + '_' + rowIndex)" -->
                                        <div class="d-flex align-center mb-2">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <div
                                            :class="['font-weight-medium', 'd-flex align-center']"
                                            style="font-size: 1.2em"
                                          >
                                            <!-- {{adjustment_products[rowIndex][r_key].primary.value}} -->
                                            <QuantityInput
                                              :ref="r_key + '_' + rowIndex"
                                              v-if="rowIndex === activeRowIndex"
                                              hide-details
                                              :disabled="pageLoading"
                                              :loading="pageLoading"
                                              :fieldType="row[r_key].type"
                                              hideSpinBtns
                                              :id="r_key + '_' + rowIndex"

                                              customClass="mr-2"
                                              placeholder="quantity"
                                              style="max-width: 100px"
                                              v-model="
                                                adjustment_products[rowIndex][r_key].primary.value
                                              "
                                              v-on:keyup="
                                                changevalue(
                                                  adjustment_products[rowIndex][r_key]?.primary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                            >
                                            </QuantityInput>
                                            <template v-else>{{
                                              r_data.primary.value ? r_data.primary.value : 0
                                            }}</template
                                            >&nbsp;
                                            
                                            <template>{{ r_data.primary.uom }}</template>
                                          </div>
                                        </div>
                                        <div class="d-flex align-center mb-0">
                                          <v-chip color="red" class="mr-2 pa-2 white--text" small
                                            >S</v-chip
                                          >
                                          <div
                                            :class="['font-weight-medium', 'd-flex align-center']"
                                            style="font-size: 1.2em"
                                          >
                                            <!-- {{adjustment_products[rowIndex][r_key].secondary.value}} -->
                                            <QuantityInput
                                              v-if="rowIndex === activeRowIndex"
                                              :disabled="pageLoading"
                                              :loading="pageLoading"
                                              style="max-width: 100px"
                                              hide-details
                                        
                                              hideSpinBtns
                                              :id="r_key + '_' + rowIndex"
                                              v-model="
                                                adjustment_products[rowIndex][r_key].secondary.value
                                              "
                                              type="number"
                                             
                                              placeholder="quantity"
                                              v-on:blur="
                                                changevalues(
                                                  adjustment_products[rowIndex][r_key]?.secondary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                              v-on:keyup="
                                                changevalues(
                                                  adjustment_products[rowIndex][r_key]?.secondary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                            >
                                              </QuantityInput
                                            >
                                            <!-- v-model="mod_FinalQty_Secondary" -->
                                            <template v-else>{{
                                              r_data.secondary.value ? r_data.secondary.value : 0
                                            }}</template
                                            >&nbsp;
                                            <template>{{ r_data.secondary.uom }}</template>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('product_name')"
                                        :class="['d-flex align-center', 'font-weight-medium']"
                                        style="font-size: 1.2em; height: 100%; min-height: 86px"
                                      >
                                        <ImageTemplate
                                          :src="r_data.value.image"
                                          :maxWidth="50"
                                          :maxHeight="50"
                                          :aspectRatio="1"
                                          :contain="false"
                                          circle
                                          customClass="mr-2"
                                        />
                                        <div :class="[{ 'px-3': rowIndex !== activeRowIndex }]" style="max-width: 200px;">
                                          <!-- <pre>{{ row[r_key].value }}</pre> -->
                                          <AutoCompleteInput
                                            v-if="rowIndex === activeRowIndex"
                                            hide-details
                                            :items="productListsss(adjustment_products[rowIndex][r_key]?.product_name)"
                                            :id="`$_product-${row[r_key]}_${rowIndex + 1}`"
                                            placeholder="Product"
                                            itemText="name_en"
                                            v-on:change="selectProduct($event, rowIndex)"

                                            itemValue="id"
                                            
                                            v-model="adjustment_products[rowIndex][r_key].product_name"
                                          >
                                          </AutoCompleteInput>
                                          <!-- {{ row[r_key] }} -->
                                          <!-- <p class="ml-2">
                                            <span>{{ r_data.value.name }}</span>
                                          </p> -->
                                          <template v-else>{{ r_data.value.name }}</template>
                                        </div>
                                      </div>

                                      <!-- <div
                                        :class="[
                                          'text-center',
                                          { 'font-weight-bold': data_h.heading.includes('final') },
                                          {
                                            'red--text':
                                              data_h.heading.includes('final') && row['type'].value.name === 'subtraction',
                                          },
                                          {
                                            'green--text':
                                              data_h.heading.includes('final') &&
                                              row['type'].value.name === 'addition',
                                          },
                                        ]"
                                        v-if="row[data_h.heading].type === 'fixed'"
                                      >
                                        {{ row[data_h.heading].value }}
                                      </div>

                                      <div v-else>
                                        <div
                                          class="d-flex flex-row align-center justify-space-evenly"
                                          v-if="row[data_h.heading].type === 'select'"
                                        >
                                          <ImageTemplate
                                            v-if="data_h.heading === 'product_name'"
                                            :src="row.image"
                                            :maxWidth="50"
                                            :maxHeight="50"
                                            :aspectRatio="1"
                                            :contain="false"
                                            circle
                                            customClass="mr-2"
                                          />
                                          <SelectInput
                                            @click.stop
                                            hide-details
                                            :items.sync="adj_dds[data_h.heading]"
                                            :id="`$_product-${row[data_h.heading]}_${idx + 1}`"
                                            :placeholder="
                                              data_h.heading.toUpperCase().replace('_', '')
                                            "
                                            itemText="title"
                                            itemValue="props"
                                            v-model="row[data_h.heading].value"
                                          ></SelectInput>

                                          <div
                                            v-if="data_h.heading === 'product_name'"
                                            class="ml-2 rounded-pill px-2 py-1"
                                            style="border: 1px solid black"
                                          >
                                            {{ row.code.value }}
                                          </div>
                                        </div>

                                        <TextInput
                                          v-else-if="row[data_h.heading].type !== 'action'"
                                          hide-details
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          :fieldType="row[data_h.heading].type"
                                          :id="row[data_h.heading].type + data_h.heading"
                                          :placeholder="
                                            data_h.heading.toUpperCase().replace('_', ' ')
                                          "
                                          :minMax="{ min: 0, max: 1000 }"
                                          v-model="row[data_h.heading].value"
                                          @change="changeInput(data_h.heading)"
                                        ></TextInput>

                                        <div v-if="data_h.heading === 'id'">{{ index + 1 }}</div>

                                        <div v-if="data_h.heading === 'drag'">
                                          <v-icon color="#0d47a1">mdi-apps</v-icon>
                                        </div>

                                        <div v-if="data_h.heading === 'action'">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                style="
                                                  font-size: 28px;
                                                  cursor: pointer;
                                                  height: 100%;
                                                  width: 100%;
                                                "
                                                color="red"
                                                @click="removeItem(index)"
                                                >mdi-delete</v-icon
                                              >
                                            </template>
                                            <span>Delete Product</span>
                                          </v-tooltip>
                                        </div>
                                      </div> -->
                                    </td>

                                    <td class="">
                                      <div class="d-flex align-center">
                                        <v-icon
                                          small
                                          style="
                                            font-size: 28px;
                                            cursor: pointer;
                                          "
                                          color="red"
                                          @click="removeItem(rowIndex)"
                                          >mdi-delete-outline</v-icon
                                        >
                                      </div>
                                    </td>
                                    <!-- <span v-if="data_h.heading === 'product_name'">
                                      <template v-for="(name_l, key) in row[data_h.heading].value">
                                        <p class="mb-0" :key="key">
                                          {{ name_l }}
                                        </p>
                                      </template>
                                    </span>
                                    <span v-else-if="data_h.heading === 'drag'">
                                      <v-icon color="#0d47a1">mdi-apps</v-icon>
                                    </span>
                                    <span v-else>{{ row[data_h.heading] }}</span> -->
                                  </tr>
                                </template>
                                <template v-else>
                                  <tr>
                                    <td :colspan="productTableHeading.length">
                                      <p class="m-0 text-center">
                                        <img
                                          width="30"
                                          :src="$assetURL('media/error/empty.png')"
                                          class="row-not-found-image mr-4"
                                        />
                                        Uhh... There are no product at the moment.
                                      </p>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-simple-table v-else  :class="['adjustment-table', 'grey lighten-4']">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    v-for="(product_h, index) in productTableHeadings"
                                    :key="'$_' + product_h.title + '_' + index + 1"
                                    :style="`max-width: ${product_h.width} !important; width ${product_h.width} !important`"
                                    :width="product_h.width"
                                    :colspan="product_h.heading.includes('$') ? 2 : 1"
                                    :class="[
                                      { 'text-center px-2': product_h.title === '#' },
                                      { 'px-3': product_h.title === '' },
                                      'font-weight-medium',
                                      'black--text',
                                    ]"
                                  >
                                    {{ product_h.title }}
                                  </th>
                                </tr>
                              </thead>

                              <!-- table body -->
                              <tbody v-if="!pageLoading" v-click-outside="clickOutside">
                                <template v-if="adjustment_productss.length">
                                  <tr
                                    v-for="(row, rowIndex) in adjustment_productss"
                                    :class="rowIndex % 2 === 0 && 'blue lighten-5'"
                                    class="product-listing-tr"
                                    :key="'$adjustment_product_k_' + rowIndex"
                                    draggable
                                    @dragstart="dragStart(rowIndex)"
                                    @dragover="dragOver(rowIndex)"
                                    @dragend="dragEnds"
                                  >
                                    <td class="cursor-move px-0">
                                      <div class="d-flex align-center justify-center">
                                        <v-icon small color="#0d47a1">mdi-apps</v-icon>
                                      </div>
                                    </td>

                                    <td class="px-0">
                                      <div class="d-flex align-center justify-center">
                                        <span>{{ rowIndex + 1 }}</span>
                                      </div>
                                    </td>

                                    <td
                                      v-for="(r_data, r_key, dataIndex) in row"
                                      :key="
                                        r_key + '_' + dataIndex + '_' + r_key + dataIndex + '_' + 5
                                      "
                                      :class="[
                                        { 'cursor-move': r_key === 'drag' },
                                        {
                                          'px-5':
                                            rowIndex !== activeRowIndex &&
                                            (r_key.includes('serial_no')),
                                        },
                                        {
                                          'px-2':
                                            rowIndex === activeRowIndex &&
                                            ( r_key.includes('serial_no')),
                                        },
                                      ]"
                                      :style="{
                                        maxWidth:
                                        productTableHeadings?.find((pt) => pt.heading === r_key)
                                            ?.width + 'px',
                                      }"
                                      :width="
                                        productTableHeadings?.find((pt) => pt.heading === r_key)?.width
                                      "
                                    >
                                      <div
                                        v-if="r_key.includes('current_stock')"
                                        style="height: 100%"
                                        class="d-flex flex-column justify-space-around"
                                      >
                                        <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.primary.value
                                          }}</span>
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.primary.uom
                                          }}</span>
                                        </div>
                                        <div class="d-flex align-center">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.secondary.value
                                          }}</span>
                                          <span class="font-weight-bold ml-2">{{
                                            r_data.secondary.uom
                                          }}</span>
                                        </div>
                                      </div>

                                     
                                      

                                      <!-- @click="onFocus($event)" -->
                                      <!-- @click="
                                          (event) => {
                                            activeRowIndex = rowIndex;
                                            $nextTick(() => {
                                              onFocus(event, r_key + '_' + rowIndex);
                                            });
                                          }
                                        " -->
                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('serial_no')"
                                        :class="['font-weight-medium', 'd-flex align-center']"
                                        style="font-size: 1.2em; height: 100%"
                                      >
                                        <TextInput
                                          :id="r_key + '_' + rowIndex"
                                          :ref="r_key + '_' + rowIndex"
                                          v-if="rowIndex === activeRowIndex"
                                          hide-details
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          :fieldType="row[r_key].type"
                                          :placeholder="r_key?.toUpperCase().replace('_', ' ')"
                                          :minMax="{ min: 0, max: 1000 }"
                                          v-model="row[r_key].value"
                                          @change="changeInput(r_key)"
                                         
                                          customClass="px-0"
                                        ></TextInput>
                                        <template v-else>{{ r_data.value }}</template>
                                      </div>

                                      <!-- (event) => {
                                        activeRowIndex = rowIndex;
                                        $nextTick(() => {
                                          onFocus(event, r_key + '_' + rowIndex);
                                        });
                                      }
                                    " -->
                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('quantity')"
                                        style="height: 100%"
                                        class="d-flex flex-column justify-space-around py-1"
                                      >
                                        <!-- @click="onFocus(r_key + '_' + rowIndex)" -->
                                        <div class="d-flex align-center mb-2">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <div
                                            :class="['font-weight-medium', 'd-flex align-center']"
                                            style="font-size: 1.2em"
                                          >
                                            <!-- {{adjustment_products[rowIndex][r_key].primary.value}} -->
                                            <QuantityInput
                                              :ref="r_key + '_' + rowIndex"
                                              v-if="rowIndex === activeRowIndex"
                                              hide-details
                                              :disabled="pageLoading"
                                              :loading="pageLoading"
                                              :fieldType="row[r_key].type"
                                              hideSpinBtns
                                              :id="r_key + '_' + rowIndex"
                                             
                                              customClass="mr-2"
                                              placeholder="quantity"
                                              style="max-width: 100px"
                                              v-model="
                                                adjustment_productss[rowIndex][r_key].primary.value
                                              "
                                               v-on:blur="
                                                stockchangevaluep(
                                                  adjustment_productss[rowIndex][r_key]?.primary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                              v-on:keyup="
                                                stockchangevaluep(
                                                  adjustment_productss[rowIndex][r_key]?.primary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                            >
                                            </QuantityInput>
                                            <template v-else>{{
                                              r_data.primary.value ? r_data.primary.value : 0
                                            }}</template
                                            >&nbsp;
                                            <template>{{ r_data.primary.uom }}</template>
                                          </div>
                                        </div>
                                        <div class="d-flex align-center mb-0">
                                          <v-chip color="red" class="mr-2 pa-2 white--text" small
                                            >S</v-chip
                                          >
                                          <div
                                            :class="['font-weight-medium', 'd-flex align-center']"
                                            style="font-size: 1.2em"
                                          >
                                            <!-- {{adjustment_products[rowIndex][r_key].secondary.value}} -->
                                            <QuantityInput
                                              v-if="rowIndex === activeRowIndex"
                                              hide-details
                                              :disabled="pageLoading"
                                              :loading="pageLoading"
                                              style="max-width: 100px"

                                              :fieldType="row[r_key].type"
                                              hideSpinBtns
                                              :id="r_key + '_' + rowIndex"
                                              v-model="
                                                adjustment_productss[rowIndex][r_key].secondary.value
                                              "
                                              
                                              placeholder="quantity"
                                              v-on:blur="
                                                stockchangevalues(
                                                  adjustment_productss[rowIndex][r_key]?.secondary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                              v-on:keyup="
                                                stockchangevalues(
                                                  adjustment_productss[rowIndex][r_key]?.secondary
                                                    .value,
                                                  rowIndex
                                                )
                                              "
                                            >
                                              </QuantityInput
                                            >
                                            <!-- v-model="mod_FinalQty_Secondary" -->
                                            <template v-else>{{
                                              r_data.secondary.value ? r_data.secondary.value : 0
                                            }}</template
                                            >&nbsp;
                                            <template>{{ r_data.secondary.uom }}</template>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        @click="onFocus($event, rowIndex)"
                                        v-else-if="r_key.includes('product_name')"
                                        :class="['d-flex align-center', 'font-weight-medium']"
                                        style="font-size: 1.2em; height: 100%; min-height: 86px"
                                      >
                                        <ImageTemplate
                                          :src="r_data.value.image"
                                          :maxWidth="50"
                                          :maxHeight="50"
                                          :aspectRatio="1"
                                          :contain="false"
                                          circle
                                          customClass="mr-2"
                                        />
                                        <div :class="[{ 'px-3': rowIndex !== activeRowIndex }]">
                                          <AutoCompleteInput
                                            v-if="rowIndex === activeRowIndex"
                                            hide-details
                                            :items="productListssss(adjustment_productss[rowIndex][r_key]?.product_name)"

                                            :id="`$_product-${row[r_key]}_${rowIndex + 1}`"
                                            placeholder=" Product"
                                            itemValue="id"
                                            itemText="name_en"

                                            v-on:change="selectProducts($event, rowIndex)"
                                            v-model="adjustment_productss[rowIndex][r_key].product_name"
                                          >
                                          </AutoCompleteInput>
                                         
                                          <template v-else>{{ r_data.value.name }}</template>
                                        </div>
                                      </div>

                    
                                    </td>

                                    <td class="">
                                      <div class="d-flex align-center">
                                        <v-icon
                                          small
                                          style="
                                            font-size: 28px;
                                            cursor: pointer;
                                          "
                                          color="red"
                                          @click="removeItems(rowIndex)"
                                          >mdi-delete-outline</v-icon
                                        >
                                      </div>
                                    </td>
                                  
                                  </tr>
                                </template>
                                <template v-else>
                                  <tr>
                                    <td :colspan="productTableHeading.length">
                                      <p class="m-0 text-center">
                                        <img
                                          width="30"
                                          :src="$assetURL('media/error/empty.png')"
                                          class="row-not-found-image mr-4"
                                        />
                                        Uhh... There are no product at the moment.
                                      </p>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <div class="mt-2">
                            <v-btn v-if="this.adjustment.stock != 'stock_transfer'"
                              depressed
                              tile
                              color="blue darken-4"
                              class="white--text"
                              @click="addMoreItem"
                            >
                              <v-icon left color="white">mdi-plus</v-icon>
                              <span style="font-size: 14px">Add Item</span>
                            </v-btn>
                            <v-btn v-else
                              depressed
                              tile
                              color="blue darken-4"
                              class="white--text"
                              @click="addMoreItems"
                            >
                              <v-icon left color="white">mdi-plus</v-icon>
                              <span style="font-size: 14px">Add Item</span>
                            </v-btn>

                          </div>
                        </v-col>

                        <!-- <v-col md="12" class="pb-0 mt-6 px-0">
                          <label for="adj-note" class="field-label mt-0 mb-1"> Note </label>
                        </v-col>
                        <v-col md="12" class="my-0 pa-0">
                          <TextAreaInput
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-on:keypress="(e) => manageLimit(e)"
                            v-on:paste="(e) => onPaste(e)"
                            counter="1024"
                            id="adj-note"
                            v-model="adjustment.note"
                            autoGrow
                          ></TextAreaInput>
                        </v-col> -->
                        <!-- <template >
        <v-btn class="white--text mr-2" depressed color="blue darken-4" tile :disabled="excelLoading"
          :loading="excelLoading" v-on:click="saveAdjustment()">
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="excelLoading" depressed tile v-on:click="importDialog = false">
          Cancel
        </v-btn>
      </template> -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>

        <!-- <Dialog style="z-index: 999" :dialog="dialog.isOpen" :dialogWidth="dialog.dialogWidth">
          <template v-slot:title>
            <div class="d-flex align-center justify-space-between" style="width: 100%">
              Add {{ dialog.title }}
              <v-btn
                :disabled="pageLoading"
                :loading="pageLoading"
                color="white--text blue darken-4"
                depressed
                tile
                @click="addItemToTheList"
              >
                New {{ dialog.title }}
              </v-btn>
            </div>
          </template>
          <template v-slot:body>
            <v-container fluid class="pa-0">
              <v-row
                style="
                  margin-top: 8px !important;
                  margin-left: 0% !important;
                  margin-right: 0% !important;
                "
                v-for="(brand, bIndx) in prod_lists[leverageStrings(dialog.title)]"
                :key="`$_brand_${bIndx}_`"
                class="h1 my-1"
              >
                <v-col md="11">
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :id="`brand_add_listing_${bIndx}`"
                    :placeholder="`Enter ${dialog.title}`"
                    :value="brand.text"
                    v-on:change="
                      (event) => {
                        prod_lists[leverageStrings(dialog.title)][bIndx].text = event;
                        prod_lists[leverageStrings(dialog.title)][bIndx].value =
                          leverageStrings(event);
                      }
                    "
                  >
                  </TextInput>
                </v-col>
                <v-col md="1" class="pa-0 d-flex align-center justify-center">
                  <v-icon
                    style="font-size: 30px"
                    v-on:click="removeFromList(bIndx)"
                    color="red"
                    outlined
                    >delete</v-icon
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
            >
              Add
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()">
              Cancel
            </v-btn>
          </template>
        </Dialog> -->
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger, find,cloneDeep,filter } from "lodash";
// import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
// import SelectInput from "@/view/components/SelectInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import ImageTemplate from "@/view/components/Image";
import { All_PRPDUCTSSS } from "@/core/lib/product.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { GET } from "@/core/services/store/request.module";
import QuantityInput from "@/view/components/QuantityInput";

// import Dialog from "@/view/components/Dialog";
// import NumberInput from "@/view/components/NumberInput";
import DatePicker from "@/view/components/DatePicker";
// import FileUpload from "@/view/components/FileUpload";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { CREATE_ADJUSTMENT } from "@/core/lib/product.lib";

import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import ImageUpload from "@/public/media/svg/files/imageupload.svg";

export default {
  name: "Product-create",
  title: "Create Product",
  data() {
    return {
      data: {},
      style: "none",
      whereToDropIndex: null,
      warehouse_Show: true,
      productList: [],
      cal: null,
      // addition:addition,
      dragStartIndex: null,
      activeRowIndex: null,
      pageLoading: false,
      formValid: true,
      productId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],

      primarySalesUOMList: [
        {
          text: "CAN",
          value: "CAN",
        },
        {
          text: "CTN",
          value: "CTN",
        },
        {
          text: "BTL",
          value: "BTL",
        },
        {
          text: "PKT",
          value: "PKT",
        },
        {
          text: "KG",
          value: "KG",
        },
        {
          text: "PCK",
          value: "PCK",
        },
        {
          text: "BAG",
          value: "BAG",
        },
        {
          text: "TRAY",
          value: "TRAY",
        },
        {
          text: "BOX",
          value: "BOX",
        },
        {
          text: "Whole",
          value: "Whole",
        },
        {
          text: "PCS",
          value: "PCS",
        },
        {
          text: "NONE",
          value: "NONE",
        },
      ],

      // supplier_form
      supplier: {
        id: null,
        supplier: null,
        supplier_part: null,
        supplier_toggle: false,
        primary_supplier_price: null,
        secondary_supplier_price: null,
      },
      calculation: [
        {
          add: {
            Addition: "addition",
            Substraction: "substraction",
          },
        },
      ],
      //   adjustment_
      adjustment_products: [
        {
          product_name: {
            value: {
              name: "Select Product",
              prefix: "",
              code: "",
              image: "",
            },
            type: "select",
          },
          current_stock: {
            primary: { value: 0, uom: "" },
            secondary: { value: 0, uom: "" },
            type: "fixed",
          },
          type: {
            value: { name: "addition" },
            type: "select",
          },
          serial_no: { value: "ABCDEF123456", type: "text" },

          quantity: {
            primary: { value: null, uom: "" },
            secondary: { value: null, uom: "" },
            type: "number",
          },
          final_quantity: {
            primary: { value: 0, uom: "" },
            secondary: { value: 0, uom: "" },
            type: "fixed",
          },
          //     adjustment: {
          //   stock: "stock_transfer",
          //   date: null,
          //   reference_no: null,
          //   file: null,
          //   from_warehouse: null,
          //   to_warehouse: null,
          // },
        },
        // {
        //   product_name: {
        //     value: {
        //       name: "Mango Juice",
        //       prefix: "cn",
        //       code: "DEF456",
        //       image:
        //         "https://upload.wikimedia.org/wikipedia/commons/7/74/Mangos_-_single_and_halved.jpg",
        //     },
        //     type: "select",
        //   },
        //   current_stock: {
        //     primary: { value: 300, uom: "CAN" },
        //     secondary: { value: 200, uom: "CTN" },
        //     type: "fixed",
        //   },
        //   type: {
        //     value: { name: "subtraction" },
        //     type: "select",
        //   },
        //   serial_no: { value: "GHIJKL789123", type: "text" },

        //   quantity: {
        //     primary: { value: null, uom: "CAN" },
        //     secondary: { value: null, uom: "CTN" },
        //     type: "number",
        //   },
        //   final_quantity: {
        //     primary: { value: 300, uom: "CAN" },
        //     secondary: { value: 200, uom: "CTN" },
        //     type: "fixed",
        //   },
        // },
      ],
      adjustment_productss: [
        {
          product_name: {
            value: {
              name: "Select Product",
              prefix: "",
              code: "",
              image: "",
            },
            type: "select",
          },
          current_stock: {
            primary: { value: 0, uom: "" },
            secondary: { value: 0, uom: "" },
            type: "fixed",
          },
          serial_no: { value: "ABCDEF123456", type: "text" },

          quantity: {
            primary: { value: null, uom: "" },
            secondary: { value: null, uom: "" },
            type: "number",
          },
         
          //     adjustment: {
          //   stock: "stock_transfer",
          //   date: null,
          //   reference_no: null,
          //   file: null,
          //   from_warehouse: null,
          //   to_warehouse: null,
          // },
        },
        // {
        //   product_name: {
        //     value: {
        //       name: "Mango Juice",
        //       prefix: "cn",
        //       code: "DEF456",
        //       image:
        //         "https://upload.wikimedia.org/wikipedia/commons/7/74/Mangos_-_single_and_halved.jpg",
        //     },
        //     type: "select",
        //   },
        //   current_stock: {
        //     primary: { value: 300, uom: "CAN" },
        //     secondary: { value: 200, uom: "CTN" },
        //     type: "fixed",
        //   },
        //   type: {
        //     value: { name: "subtraction" },
        //     type: "select",
        //   },
        //   serial_no: { value: "GHIJKL789123", type: "text" },

        //   quantity: {
        //     primary: { value: null, uom: "CAN" },
        //     secondary: { value: null, uom: "CTN" },
        //     type: "number",
        //   },
        //   final_quantity: {
        //     primary: { value: 300, uom: "CAN" },
        //     secondary: { value: 200, uom: "CTN" },
        //     type: "fixed",
        //   },
        // },
      ],

      productTableHeading: [
        { tooltip: "", title: "", heading: "drag", width: 10 },

        { tooltip: "", title: "#", heading: "id", width: 20 },
        {
          tooltip: "Product Name",
          title: "Product Name",
          heading: "product_name",
          width: 200,
        },
        {
          tooltip: "Current Stock",
          title: "Current Stock",
          heading: "current_stock",
          width: 100,
        },
        { tooltip: "Type", title: "Type", heading: "type", width: 120 },
        { tooltip: "Serial No", title: "Serial No", heading: "serial_no", width: 135 },
        {
          tooltip: "Quantity",
          title: "Quantity",
          heading: "quantity",
          width: 150,
        },
        {
          tooltip: "Final Quantity",
          title: "Final Quantity",
          heading: "final_quantity",
          width: 150,
        },

        {
          tooltip: "Adjustment",
          title: "Action",
          heading: "adjustment",
          width: 150,
        },
        // { tooltip: "", title: "", heading: "action", width: 20 },
      ],
      productTableHeadings: [
        { tooltip: "", title: "", heading: "drag", width: 10 },

        { tooltip: "", title: "#", heading: "id", width: 20 },
        {
          tooltip: "Product Name",
          title: "Product Name",
          heading: "product_name",
          width: 200,
        },
        {
          tooltip: "Current Stock",
          title: "Current Stock",
          heading: "current_stock",
          width: 100,
        },
        { tooltip: "Serial No", title: "Serial No", heading: "serial_no", width: 135 },

        {
          tooltip: "Quantity",
          title: "Quantity",
          heading: "quantity",
          width: 150,
        },

        {
          tooltip: "Adjustment",
          title: "Action",
          heading: "adjustment",
          width: 150,
        },
        { tooltip: "", title: "", heading: "action", width: 20 },
      ],

      adj_dds: {
        type: [
          { title: "Addition", props: { name: "addition" } },
          { title: "Subtraction", props: { name: "subtraction" } },
        ],
        product_name: [
          {
            title: "Pineapple Juice",
            props: {
              name: "Pineapple Juice",
              prefix: "en",
              code: "ABC123",
              image: "https://upload.wikimedia.org/wikipedia/commons/3/3b/Batido_de_pi%C3%B1a.jpg",
            },
          },
          {
            title: "Mango Juice",
            props: {
              name: "Mango Juice",
              prefix: "cn",
              code: "DEF456",
              image:
                "https://upload.wikimedia.org/wikipedia/commons/7/74/Mangos_-_single_and_halved.jpg",
            },
          },
          {
            title: "Apple Juice",
            props: {
              name: "Apple Juice",
              prefix: "my",
              code: "GHI789",
              image: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Apfelsaft_im_Glas.jpg",
            },
          },
        ],
      },

      adjustment: {
        stock: "stock_adjustment",
        date: null,
        reference_no: null,
        file: null,
        from_warehouse: "Sutra",
        to_warehouse: null,
        note: null,
      },

      // product: {
      //   name_en: null,
      //   name_cn: null,
      //   name_my: null,
      //   brand: null,
      //   category: null,
      //   deptCategory: null,
      //   reorder_trigger: null,
      //   reorder_type: { text: "Primary", value: "primary" },
      //   primary_sales_uom: null,
      //   secondary_purchase_uom: null,
      //   unit_description_psu: null,
      //   unit_description_spu: null,
      //   image: null,

      //   // All suppliers
      //   suppliers: [
      //     {
      //       id: 1,
      //       supplier_name: null,
      //       supplier_part: null,
      //       supplier_status: false,
      //       primary_sales_price: null,
      //       secondary_purchase_price: null,
      //     },
      //   ],

      //   // Both warehouses
      //   warehouse: {
      //     sutra: {
      //       primary_quantity: null,
      //       secondary_quantity: null,
      //     },
      //     nusa: {
      //       primary_quantity: null,
      //       secondary_quantity: null,
      //     },
      //   },
      // },

      // top stepper
      stepper: {
        value: 1,
        header: [{ title: "Basic Details" }, { title: "UOM" }, { title: "Supplier" }],
      },

      prod_lists: {
        warehouse: [
          {
            text: "Warehouse A",
            value: "Warehouse A",
          },
          {
            text: "Warehouse B",
            value: "Warehouse B",
          },
        ],
        brand: [
          {
            text: "Videocon",
            value: "videocon",
          },
          {
            text: "Samsung",
            value: "samsung",
          },
          {
            text: "Cristofori",
            value: "cristofori",
          },
          {
            text: "Medeli",
            value: "medeli",
          },
          {
            text: "Greg Bennett",
            value: "greg_bennett",
          },
          {
            text: "FSF",
            value: "fsf",
          },
        ],
        category: [
          {
            text: "Disposable",
            value: "disposable",
          },
          {
            text: "Maintainance",
            value: "maintainance",
          },
          {
            text: "Adhoc",
            value: "adhoc",
          },
          {
            text: "Equipment",
            value: "equipment",
          },
          {
            text: "Bar",
            value: "bar",
          },
          {
            text: "Chinese",
            value: "chinese",
          },
          {
            text: "Vegetable",
            value: "vegetable",
          },
          {
            text: "Western",
            value: "western",
          },
          {
            text: "CW",
            value: "cw",
          },
          {
            text: "Marketing",
            value: "marketing",
          },
          {
            text: "Service",
            value: "service",
          },
        ],
        department_category: [
          {
            text: "Kitchen",
            value: "kitchen",
          },
          {
            text: "Bar Alcoholic",
            value: "bar_alcoholic",
          },
          {
            text: "Non Bar Alcoholic",
            value: "non_bar_alcoholic",
          },
        ],
      },

      prod_lists_add_templates: {
        brand: {
          text: "",
          value: "",
        },
        category: {
          text: "",
          value: "",
        },
        department_category: {
          text: "",
          value: "",
        },
      },

      dialog: {
        isOpen: false,
        dialogWidth: 500,
        title: "",
      },

      stock_radio: [
        { title: "Stock Transfer", value: "stock_transfer" },
        { title: "Stock Adjustment", value: "stock_adjustment" },
      ],
    };
  },
  components: {
    // TextAreaInput,
    DatePicker,
    // FileUpload,
    QuantityInput,
    TextInput,
    // SelectInput,
    AutoCompleteInput,
    ImageTemplate,
    // QuantityInput
  },
  computed: {
    productListsss: () =>{
    
    return function (id)
       {
       let _vendorList = cloneDeep(this.adj_ddss);
       console.log(id)
       console.log(_vendorList,'_vendorList')
       const newArray = filter(_vendorList, (row) => {
         console.log(row.id,'ids')
         console.log({sss:(row.value != id?.id)})

         if (row.id != id) {
          console.log(this.adjustment_products,'cdscf')
           if (!find(this.adjustment_products, (inner_row) => inner_row?.product_name.product_name == row.id)) {
             return row;
           }
         } else {
           return row;
         }
       });
       console.log({newArray});
       return newArray;
     };
     

   },
   productListssss: () =>{
    
    return function (id)
       {
       let _vendorList = cloneDeep(this.adj_ddss);
       console.log(id)
       console.log(_vendorList,'_vendorList')
       const newArray = filter(_vendorList, (row) => {
         console.log(row.id,'ids')
         console.log({sss:(row.value != id?.id)})

         if (row.id != id) {
          console.log(this.adjustment_productss,'cdscf')
           if (!find(this.adjustment_productss, (inner_row) => inner_row?.product_name.product_name == row.id)) {
             return row;
           }
         } else {
           return row;
         }
       });
       console.log({newArray});
       return newArray;
     };
     

   }
   
  },
  watch: {},
  methods: {
    getWarehouse(){
      this.$store
        .dispatch(GET, {
          url: `warehouses`,
          
        })
        .then((data) => {
          console.log(data)
          this.warehouses= data;
          console.log(this.warehouses,'gjgfjg')
          
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });

    },
    manageLimit(e) {
      if (this.adjustment.note && this.adjustment.note.length > 1023) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.adjustment.note;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 29) {
        let trimValue = finalval.substring(0, 30);
        this.adjustment.note = trimValue;
        e.preventDefault();
      }
    },
    
    calcType(e, r_key) {
      if (e.name == "subtraction") {
        if (
          Number(this.adjustment_products[r_key]?.quantity.primary.value) >
          Number(this.adjustment_products[r_key].current_stock.primary.value)
        ) {
          this.adjustment_products[r_key].quantity.primary.value = 0;
          this.adjustment_products[r_key].final_quantity.primary.value =
            (Number(this.adjustment_products[r_key].current_stock.primary.value) -
            Number(this.adjustment_products[r_key]?.quantity.primary.value)).toFixed(3);
        } else {
          this.adjustment_products[r_key].final_quantity.primary.value =
            (Number(this.adjustment_products[r_key].current_stock.primary.value) -
            Number(this.adjustment_products[r_key]?.quantity.primary.value)).toFixed(3);
        }
        if (
          Number(this.adjustment_products[r_key]?.quantity.secondary.value) >
          Number(this.adjustment_products[r_key].current_stock.secondary.value)
        ) {
          this.adjustment_products[r_key].quantity.secondary.value = 0;
          this.adjustment_products[r_key].final_quantity.secondary.value =
            (Number(this.adjustment_products[r_key].current_stock.secondary.value) -
            Number(this.adjustment_products[r_key]?.quantity.secondary.value)).toFixed(3);
        } else {
          this.adjustment_products[r_key].final_quantity.secondary.value =
            (Number(this.adjustment_products[r_key].current_stock.secondary.value) -
            Number(this.adjustment_products[r_key]?.quantity.secondary.value)).toFixed(3);
        }
      } else {
        this.adjustment_products[r_key].final_quantity.primary.value =
          (Number(this.adjustment_products[r_key].final_quantity.primary.value) +
          Number(this.adjustment_products[r_key]?.quantity.primary.value)).toFixed(3);
        this.adjustment_products[r_key].final_quantity.secondary.value =
          (Number(this.adjustment_products[r_key].final_quantity.secondary.value) +
          Number(this.adjustment_products[r_key]?.quantity.secondary.value)).toFixed(3);
      }
    },
    changevalue(event, r_key) {
      // this.adjustment_products[r_key].type.name;

      // console.log(event);
      this.cal = this.adjustment_products[r_key].type.value.name;
      if (this.cal == "subtraction") {
        if (Number(event) > Number(this.adjustment_products[r_key].current_stock.primary.value)) {
          this.adjustment_products[r_key].quantity.primary.value = 0;
        } else {
          this.adjustment_products[r_key].final_quantity.primary.value =
            this.adjustment_products[r_key].current_stock.primary.value;
          this.adjustment_products[r_key].final_quantity.primary.value =
            (this.adjustment_products[r_key].final_quantity.primary.value - Number(event)).toFixed(3);
        }
      } else {
        this.adjustment_products[r_key].final_quantity.primary.value =
          this.adjustment_products[r_key].current_stock.primary.value;
        this.adjustment_products[r_key].final_quantity.primary.value =
          (Number(this.adjustment_products[r_key].final_quantity.primary.value) + Number(event)).toFixed(3);
      }
      console.log(this.cal, "cal");
    },
    stockchangevalues(event, r_key) {
  console.log(this.adjustment_productss[r_key].current_stock.primary.value,'this.adjustment_productss[r_key].current_stock.primary.value')
        if (Number(event) > Number(this.adjustment_productss[r_key].current_stock.secondary.value)) {
          
          this.adjustment_productss[r_key].quantity.secondary.value = 0;
        } 
      console.log(this.cal, "cal");
    },
    stockchangevaluep(event, r_key) {
  console.log(this.adjustment_productss[r_key].current_stock.primary.value,'this.adjustment_productss[r_key].current_stock.primary.value')
        if (Number(event) > Number(this.adjustment_productss[r_key].current_stock.primary.value)) {
          
          this.adjustment_productss[r_key].quantity.primary.value = 0;
        } 
      console.log(this.cal, "cal");
    },
    changevalues(event, r_key) {
      // alert(1)

      this.cals = this.adjustment_products[r_key].type.value.name;
      if (this.cals == "subtraction") {
        this.adjustment_products[r_key].final_quantity.secondary.value =
          this.adjustment_products[r_key].current_stock.secondary.value;
        // alert(event)
        if (
          Number(event) > Number(this.adjustment_products[r_key].final_quantity.secondary.value)
        ) {
          this.adjustment_products[r_key].quantity.secondary.value = 0;
        } else {
          this.adjustment_products[r_key].final_quantity.secondary.value =
            (Number(this.adjustment_products[r_key].final_quantity.secondary.value) - Number(event)).toFixed(3);
        }
      } else if (this.cals == "addition") {
        this.adjustment_products[r_key].final_quantity.secondary.value =
          this.adjustment_products[r_key].current_stock.secondary.value;

        this.adjustment_products[r_key].final_quantity.secondary.value =
          (Number(this.adjustment_products[r_key].final_quantity.secondary.value) + Number(event)).toFixed(3);
      }
      console.log(this.cals, "cals");
    },

    changevaluess(event, r_key) {

      this.cals = this.adjustment_productss[r_key]?.type?.value?.name;
      if (this.cals == "subtraction") {
        this.adjustment_productss[r_key].final_quantity.secondary.value =
          this.adjustment_productss[r_key].current_stock.secondary.value;
        // alert(event)
        if (
          Number(event) > Number(this.adjustment_productss[r_key].final_quantity.secondary.value)
        ) {
          this.adjustment_productss[r_key].quantity.secondary.value = 0;
        } else {
          this.adjustment_productss[r_key].final_quantity.secondary.value =
            Number(this.adjustment_productss[r_key].final_quantity.secondary.value) - Number(event);
        }
      } else if (this.cals == "addition") {
        this.adjustment_productss[r_key].final_quantity.secondary.value =
          this.adjustment_productss[r_key].current_stock.secondary.value;

        this.adjustment_productss[r_key].final_quantity.secondary.value =
          Number(this.adjustment_productss[r_key].final_quantity.secondary.value) + Number(event);
      }
      console.log(this.cals, "cals");
    },

    selectProduct(event, r_key) {
      console.log(event,'ccc')
      this.adjustment_products[r_key].quantity.primary.value =0;
        this.adjustment_products[r_key].quantity.secondary.value =0;
      this.filterProduct = find(this.productList, {
        id: event,
      });
      this.adjustment_products[r_key].current_stock.primary.value =
        this.filterProduct?.singlewarehouse.primary_qty;
      this.adjustment_products[r_key].current_stock.secondary.value =
        this.filterProduct?.singlewarehouse.secondary_qty;
      this.adjustment_products[r_key].current_stock.primary.uom =
        this.filterProduct?.singlewarehouse.primary_uom;
      this.adjustment_products[r_key].current_stock.secondary.uom =
        this.filterProduct?.singlewarehouse.secondary_uom;
      this.adjustment_products[r_key].product_name.value.name =
        this.filterProduct?.name_en + " - " + this.filterProduct?.barcode;
      this.adjustment_products[r_key].product_name.value.image =
        this.filterProduct?.profile_logo?.file?.url;
      this.adjustment_products[r_key].final_quantity.primary.value =
        this.filterProduct?.singlewarehouse.primary_qty;
      this.adjustment_products[r_key].final_quantity.primary.uom =
        this.filterProduct?.singlewarehouse.primary_uom;
      this.adjustment_products[r_key].final_quantity.secondary.uom =
        this.filterProduct?.singlewarehouse.secondary_uom;
      this.adjustment_products[r_key].final_quantity.secondary.value =
        this.filterProduct?.singlewarehouse?.secondary_qty;
      this.adjustment_products[r_key].quantity.primary.uom =
        this.filterProduct?.singlewarehouse?.primary_uom;
      this.adjustment_products[r_key].quantity.secondary.uom =
        this.filterProduct?.singlewarehouse?.secondary_uom;
      this.value = this.filterProduct?.unit_value;
      
      console.log(this.value, "value");

      console.log(this.adjustment_products[r_key].type.value.name);
    },
    selectProducts(event, r_key) {
      this.adjustment_productss[r_key].quantity.primary.value =0;
        this.adjustment_productss[r_key].quantity.secondary.value =0;
      this.filterProduct = find(this.productList, {
        id: event,
      });
      console.log(this.filterProduct,'filterProduct')
      this.adjustment_productss[r_key].current_stock.primary.value =
        this.filterProduct?.singlewarehouse?.primary_qty;
      this.adjustment_productss[r_key].current_stock.secondary.value =
        this.filterProduct?.singlewarehouse?.secondary_qty;
      this.adjustment_productss[r_key].current_stock.primary.uom =
        this.filterProduct?.singlewarehouse?.primary_uom;
      this.adjustment_productss[r_key].current_stock.secondary.uom =
        this.filterProduct?.singlewarehouse?.secondary_uom;
      this.adjustment_productss[r_key].product_name.value.name =
        this.filterProduct?.name_en + " - " + this.filterProduct?.barcode;
      this.adjustment_productss[r_key].product_name.value.image =
        this.filterProduct?.profile_logo?.file?.url;
    
    
      
     
      this.adjustment_productss[r_key].quantity.primary.uom =
        this.filterProduct?.singlewarehouse?.primary_uom;
      this.adjustment_productss[r_key].quantity.secondary.uom =
        this.filterProduct?.singlewarehouse?.secondary_uom;
      this.value = this.filterProduct?.unit_value;

    },
    changeInput(event, key) {
      console.log({ value: event, key, idx: this.activeRowIndex });
      console.log(+event);

    },
    clickOutside() {
      console.log({ activeRowIndex: this.activeRowIndex });
      this.activeRowIndex = null;
    },
    onFocus(event, rowIndex) {
      this.activeRowIndex = rowIndex;
      this.$nextTick(() => {
        const ourForm = event.target;
        const ourInp = ourForm.querySelector("input");
        if (ourInp) ourInp.focus() || ourInp.select();
      });
    },
    dragStart(rowIndex) {
      this.dragStartIndex = rowIndex;
    },
    dragOver(overIndex) {
      this.whereToDropIndex = overIndex;
    },
    dragEnd() {
      const whomeToDrop = this.adjustment_products[this.dragStartIndex];
      this.adjustment_products.splice(this.dragStartIndex, 1);
      this.adjustment_products.splice(this.whereToDropIndex, 0, whomeToDrop);
    },
    dragEnds() {
      const whomeToDrop = this.adjustment_productss[this.dragStartIndex];
      this.adjustment_productss.splice(this.dragStartIndex, 1);
      this.adjustment_productss.splice(this.whereToDropIndex, 0, whomeToDrop);
    },
    removeItem(index) {
      this.adjustment_products.splice(index, 1);
    },
    removeItems(index) {
      this.adjustment_productss.splice(index, 1);
    },
    addMoreItem() {
      this.adjustment_products.push({
        product_name: {
          value: {
            name: "Select Product",
            prefix: "",
            code: "",
            image: "",
          },
          type: "select",
        },
        current_stock: {
          primary: { value: 0, uom: "" },
          secondary: { value: 0, uom: "" },
          type: "fixed",
        },
        type: {
          value: { name: "addition" },
          type: "select",
        },
        serial_no: { value: "", type: "text" },

        quantity: {
          primary: { value: "", uom: "" },
          secondary: { value: "", uom: "" },
          type: "number",
        },
        final_quantity: {
          primary: { value: 0, uom: "" },
          secondary: { value: 0, uom: "" },
          type: "fixed",
        },
      });
    },
    addMoreItems() {
      this.adjustment_productss.push({
        product_name: {
          value: {
            name: "Select Product",
            prefix: "",
            code: "",
            image: "",
          },
          type: "select",
        },
        current_stock: {
          primary: { value: 0, uom: "" },
          secondary: { value: 0, uom: "" },
          type: "fixed",
        },
       
        serial_no: { value: "", type: "text" },

        quantity: {
          primary: { value: "", uom: "" },
          secondary: { value: "", uom: "" },
          type: "number",
        },
       
      });
    },
    addItemToTheList() {
      const itemToAdd = {
        ...this.prod_lists_add_templates[this.leverageStrings(this.dialog.title)],
      };
      this.prod_lists[this.leverageStrings(this.dialog.title)].unshift(itemToAdd);
    },
    removeFromList(index) {
      this.prod_lists[this.leverageStrings(this.dialog.title)].splice(index, 1);
    },
    leverageStrings(string) {
      return string.toLowerCase().split(" ").join("_");
    },
    closeDialog() {
      this.dialog = {
        ...this.dialog,
        isOpen: !this.dialog.isOpen,
        title: "",
      };
    },
    openDialog(title, dialogWidth, isOpen) {
      this.dialog = {
        ...this.dialog,
        title,
        dialogWidth,
        isOpen,
      };
    },

    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
    async handleFilesUpload() {
      try {
        const { fileInput } = this.$refs;
        if (fileInput && fileInput.$refs) {
          const { input } = fileInput.$refs;
          if (input && input.files) {
            const formData = new FormData();
            formData.append(`files[0]`, input.files[0]);
            this.pageLoading = true;
          }
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.pageLoading = false;
      }
    },
    removeImage() {},
    async updateOrCreate() {
      // console.log(this.adjustment_productss,'adjustment_products')
      const _this = this;
      if (!_this.$refs.productAddForm.validate()) {
        return false;
      }
      for (let i = 0; i < this.adjustment_products.length; i++) {
        
        if (
          this.adjustment_products &&
          this.adjustment_products[i] &&
          !this.adjustment_products[i]?.product_name
        ) {
          _this.$store.commit(SET_ERROR, [
            { model: true, message: "The product field is required" },
          ]);
          return false;
        }
        console.log(this.adjustment_products[i]?.quantity?.primary?.value,'p')
        console.log(this.adjustment_products[i]?.quantity?.secondary?.value,'s')
        if (
          this.adjustment_products &&
          this.adjustment_products[i] &&
          (!this.adjustment_products[i]?.quantity?.primary?.value && !this.adjustment_products[i]?.quantity?.secondary?.value)
        ) {
          _this.$store.commit(SET_ERROR, [
            { model: true, message: "The  quantity field is required" },
          ]);
          return false;
        }
       
      }

      const formErrors = _this.validateForm(_this.$refs.productAddForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          this.adjustment.adjustment_products = this.adjustment_products;

          const customer = await CREATE_ADJUSTMENT(_this.adjustment);
          console.log(customer);
          _this.$router.replace({
            name: "Adjustments",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Ajustment Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async updateOrCreateTransfer() {
      console.log(this.adjustment_productss,'adjustment_products')
      const _this = this;
      if (!_this.$refs.productAddForm.validate()) {
        return false;
      }
      for (let i = 0; i < this.adjustment_productss.length; i++) {
        
        if (
          this.adjustment_productss &&
          this.adjustment_productss[i] &&
          !this.adjustment_productss[i]?.product_name
        ) {
          _this.$store.commit(SET_ERROR, [
            { model: true, message: "The product field is required" },
          ]);
          return false;
        }
        if (
          this.adjustment_productss &&
          this.adjustment_productss[i] &&
          !this.adjustment_productss[i]?.quantity?.primary?.value && !this.adjustment_productss[i]?.quantity?.secondary?.value
        ) {
          _this.$store.commit(SET_ERROR, [
            { model: true, message: "The  quantity field is required" },
          ]);
          return false;
        }
        // if (
        //   this.adjustment_productss &&
        //   this.adjustment_productss[i] &&
        //   !this.adjustment_productss[i]?.quantity?.secondary?.value
        // ) {
        //   _this.$store.commit(SET_ERROR, [
        //     { model: true, message: "The secondary quantity field is required" },
        //   ]);
        //   return false;
        // }
      }

      const formErrors = _this.validateForm(_this.$refs.productAddForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          this.adjustment.adjustment_products = this.adjustment_productss;

          const customer = await CREATE_ADJUSTMENT(_this.adjustment);
          console.log(customer);
          _this.$router.replace({
            name: "Adjustments",
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Ajustment Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        title: customer.title,
        first_name: customer.first_name,
        last_name: customer.last_name,
        email: customer.email,
        date_of_birth: customer.date_of_birth,
        gender: customer.gender,
        phone_number: customer.phone_number,
        address_line_1: customer.address_line_1,
        address_line_2: customer.address_line_2,
        unit_number: customer.unit_number,
        postal_code: customer.postal_code,
        country: customer.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
    addSupplier() {
      const supplier = { ...this.supplier, id: this.product.suppliers.length + 1 };
      this.product.suppliers.push(supplier);
    },

    removeSupplier(index) {
      this.product.suppliers.splice(index, 1);
    },
    async getProducts() {
      const _this = this;

      this.pageLoading = true;
      try {
        const { rows, current_page, showingFrom, showingTo, totalrows, totalPages, status_list } =
          await All_PRPDUCTSSS(
            this.currentPage
            //this.per_page
          );
        this.productList = rows;
        this.adj_ddss = this.productList;
        const result = this.adj_ddss.map((item) => {
          return {
            title: item.name_en,
            id: item.id,
            props: {
              name: "Pineapple Juice",
              prefix: "en",
              code: "ABC123",
              image: "",
              id: item.id,
            },
          };
        });
        this.adj_dds.product_name = result;
        console.log(result);
        this.total = totalrows;
        this.status_list = status_list;
        this.totalPages = totalPages;
        console.log(this.status_list);
        this.currentPage = current_page;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.status_list = status_list;
        this.totalrows = totalrows;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
  },
  mounted() {
    this.getProducts();
    this.getWarehouse();

    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "product-update") {
      const { id } = params;
      if (id) {
        this.productId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style scoped>
.v-input {
  margin-top: 0% !important;
}

.field-label {
  font-size: 1rem;
  font-weight: 500;
}

.product-simple-table tr:hover {
  background: #fff !important;
}

:is(.adjustment-table table) thead tr th,
tbody tr td span {
  font-size: 13px;
}

:is(.adjustment-table table) thead tr th,
tbody tr td {
  border: 1px solid #6666;
}
</style>
